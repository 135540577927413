/* eslint-disable array-callback-return */
import {
  faCheck,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ProductInterface } from "../interfaces/ServerInterfaces";
import imageDefault from "../assets/jpg/no-imagen-shop.jpg"
import { getColorShowRemainQuantities, getShowRemainQuantities, printPriceWithCommasAndPeriods } from "../utils/functions";
import { RootState } from "../store/store";
import { useAppSelector } from "../store/hooks";

interface Props {
  product: ProductInterface;
  onClick: Function;
  priceSystemId: number;
  cardWidth: string;
  addCart: Function;
  includeShop: boolean
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const CardProduct = ({
  product,
  onClick,
  priceSystemId,
  cardWidth,
  addCart,
  includeShop
}: Props) => {

  const { currentCurrency } = useAppSelector((state: RootState) => state.session)

  return (
    <>
      <div
        className={`h-full ${cardWidth} border-2 border-slate-200 scrollbar-hide  overflow-hidden inline-block  cursor-pointer  ease-in-out duration-300`}
      >
        <div className="h-72  bg-slate-200 group-hover:opacity-75 ">
          {
            (<img
              src={
                product?.images
                  .length !==
                  undefined &&
                  product?.images.length > 0
                  ? product?.images[0]
                    .src
                  : imageDefault
              }
              alt={product?.name}
              className="h-full w-full object-cover object-center"
              onClick={() => onClick(product)}
            />)
          }
        </div>

        <div className="flex flex-1 w-full whitespace-nowrap  flex-col space-y-1 p-2">

          {/* Nombre */}
          <h3
            className=""
            onClick={() => onClick(product)}
          >
            {product.name}
          </h3>

          {/* Descripcion */}
          <p className="text-sm truncate h-5 -mt-2 text-slate-500">
            {" "}
            {product.description}
          </p>

          {/* Mostrar cantidades restantes */}
          {product.showRemainQuantities &&
            <p className=
              {classNames(
                `${getColorShowRemainQuantities(product.totalQuantity)}`
                ,
                "text-xs font-medium truncate h-5 -mt-4"
              )}
            >
              {" "}

              {getShowRemainQuantities(product.totalQuantity)

              }
            </p>}

          {/* Agotado o En existencia */}
          <div className="flex items-center justify-start my-2">
            {

              (product.totalQuantity > 0 || (product.stockLimit && product.totalQuantity > 0)) && (
                <FontAwesomeIcon
                  icon={faCheck}
                  className=" mx-1"
                  size="1x"
                  aria-hidden="true"
                  color="yellow"
                />
              )
            }

            <p className={`${(product.totalQuantity > 0 || (product.stockLimit && product.totalQuantity > 0)) ? "text-black" : "text-red-400"} font-semibold`}>
              {(product.type === "ADDON" || product.type === "MENU" || product.type === "SERVICE")
                ? "En existencia"
                : (product.stockLimit && product?.totalQuantity === 0) || product?.totalQuantity === 0
                  ? "Agotado"
                  : product.stockLimit && product?.totalQuantity > 0
                    ? product.type === "VARIATION"
                      ? "En existencia"
                      : "En existencia"
                    : product.stockLimit
                      ? "Agotado"
                      : product.type === "VARIATION"
                        ? "En existencia"
                        : "En existencia"
              }
            </p>
          </div>

          <div className="flex flex-col md:flex-row flex-nowrap ">

            {/* Precio de producto */}
            <h5
              className={`text-base  font-medium  text-yellow-400 ${product.onSale && "line-through text-slate-300"
                }`}
            >
              {product.prices.length === 1
                // ?  product.prices[0].price + " " + product.prices[0].codeCurrency
                ? printPriceWithCommasAndPeriods((product.prices[0].price)) + " " + product.prices[0].codeCurrency
                // ? Intl.NumberFormat("en-IN").format(product.prices[0].price) + (Number.isInteger(product.prices[0].price) ? ".00 " : " ") + product.prices[0].codeCurrency
                : product.prices.map(
                  (item) => {
                    if (item.codeCurrency === currentCurrency) {
                      return (printPriceWithCommasAndPeriods(item.price) + " " + item.codeCurrency)
                    }
                  }
                )}
            </h5>

            {/* precio de producto en oferta */}
            {product.onSale && (
              <h5 className={`text-base font-medium ml-0 md:ml-2 text-yellow-400 `}>
                {printPriceWithCommasAndPeriods(product.onSalePrice?.amount) +
                  " " +
                  product.onSalePrice?.codeCurrency}
              </h5>
            )}


          </div>
          {
            !includeShop ?
              <button
                type="button"
                onClick={() => {
                  onClick(product)
                }}
                className="w-full overflow-hidden truncate   inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-yellow-100  py-2 text-sm font-medium text-yellow-700 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
              >
                Ver más
              </button>
              :
              <>
                <button
                  type="button"
                  className={"bg-yellow-400 group flex items-center justify-center focus:ring-yellow-400 w-full uppercase overflow-hidden truncate  text-center border border-transparent py-2 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2"}
                  onClick={() => {
                    // (product.type === "ADDON" || product.type === "MENU" || product.type === "SERVICE")
                    //   ? addCart({
                    //     ...product,
                    //     prices: [
                    //       {
                    //         price: product.prices.find(elem => elem.codeCurrency === currentCurrency)?.price,
                    //         codeCurrency: product.prices.find(elem => elem.codeCurrency === currentCurrency)?.codeCurrency
                    //       }
                    //     ]
                    //   })
                    //   : (product.type === "VARIATION"
                    //     ? onClick(product)
                    //     : (product.totalQuantity === 0 || (product.stockLimit && product.totalQuantity === 0))
                    //       ? onClick(product)
                    //       : addCart({
                    //         ...product,
                    //         prices: [
                    //           {
                    //             price: product.prices.find(elem => elem.codeCurrency === currentCurrency)?.price,
                    //             codeCurrency: product.prices.find(elem => elem.codeCurrency === currentCurrency)?.codeCurrency
                    //           }
                    //         ]
                    //       }))
                    onClick(product)
                  }}
                >

                  <div className="block md:hidden group-hover:block  text-center">
                    {/* {(product.type === "ADDON" || product.type === "MENU" || product.type === "SERVICE")
                      ? <FontAwesomeIcon
                        icon={faCartShopping}
                        className="text-center self-center"
                        size="1x"
                        aria-hidden="true"
                        color="white"
                      />
                      : (product.stockLimit && product?.totalQuantity === 0) || product?.totalQuantity === 0
                        ? <FontAwesomeIcon
                          icon={faEye}
                          className="text-center self-center"
                          size="1x"
                          aria-hidden="true"
                          color="white"
                        />
                        : product.stockLimit && product?.totalQuantity > 0
                          ? product.type === "VARIATION"
                            ? <FontAwesomeIcon
                              icon={faEye}
                              className="text-center self-center"
                              size="1x"
                              aria-hidden="true"
                              color="white"

                            />
                            : <FontAwesomeIcon
                              icon={faCartShopping}
                              className="text-center self-center"
                              size="1x"
                              aria-hidden="true"
                              color="white"
                            />
                          : product.stockLimit
                            ? <FontAwesomeIcon
                              icon={faEye}
                              className="text-center self-center"
                              size="1x"
                              aria-hidden="true"
                              color="white"
                            />
                            : product.type === "VARIATION"
                              ? <FontAwesomeIcon
                                icon={faEye}
                                className="text-center self-center"
                                size="1x"
                                aria-hidden="true"
                                color="white"
                              />
                              : <FontAwesomeIcon
                                icon={faCartShopping}
                                className="text-center self-center"
                                size="1x"
                                aria-hidden="true"
                                color="white"
                              />
                    } */}
                    <FontAwesomeIcon
                      icon={faEye}
                      className="text-center self-center"
                      size="1x"
                      aria-hidden="true"
                      color="white"
                    />
                  </div>

                  <div className="hidden md:block group-hover:hidden text-center">
                    <p className="text-center">
                      {/* {(product.type === "ADDON" || product.type === "MENU" || product.type === "SERVICE")
                        ? "Añadir al carrito"
                        : (product.stockLimit && product?.totalQuantity === 0) || product?.totalQuantity === 0
                          ? "Leer más"
                          : product.stockLimit && product?.totalQuantity > 0
                            ? product.type === "VARIATION"
                              ? "Mostrar opciones"
                              : "Añadir al carrito"
                            : product.stockLimit
                              ? "Leer más"
                              : product.type === "VARIATION"
                                ? "Mostrar opciones"
                                : "Añadir al carrito"
                      } */}
                      Leer más
                    </p>
                  </div>
                </button>
              </>
          }
        </div>
      </div>
    </>
  );
};
