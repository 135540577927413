import image from "../assets/logo_full.png";

const LoadingShop = () => {
  return (
    <div className="relative h-screen w-screen bg-gradient-to-br from-yellow-50 to-white flex justify-center items-center">
      <div className="h-40 w-40 rounded-full bg-gradient-to-br from-yellow-400 via-yellow-50 to-white flex justify-center items-center animate-spin">
        <div className="rounded-full h-[150px] w-[150px] bg-white flex justify-center items-center"></div>
      </div>
      <img src={image} className="h-32 absolute" />
    </div>
  );
};

export default LoadingShop;
