import React from "react";
import { createRoot } from "react-dom/client";
// import { Provider } from 'react-redux';
import { persistor } from "./store/store";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { MainRoute } from "./routes/MainRoute";
import { Providers } from "./store/Providers";
import { PersistGate } from "redux-persist/integration/react";
import LoadingShop from "./components/LoadingShop";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <Providers>
    <PersistGate persistor={persistor} loading={<LoadingShop />}>
      <MainRoute />
    </PersistGate>
  </Providers>
  // <React.StrictMode>
  //   <Provider store={store}>
  //    <MainRoute/>
  //   </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
