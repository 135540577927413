import { Fragment, useState, createContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OriginalInfo, ProductCtx, ProductInterface, VariationsProd } from "../../../interfaces/ServerInterfaces";
import imageDefault from "../../../assets/jpg/no-imagen-shop.jpg"
import { getColorShowRemainQuantities, getShowRemainQuantities, printPriceWithCommasAndPeriods } from "../../../utils/functions";
import { CarrouselProductImages } from "../../CarrouselProductImages";
import ProductVariations from "./ProductVariations";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";

interface Props {
  onClick: Function;
  product: ProductInterface | null;
  addCart: Function;
  includeShop: boolean
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const productInfoContex: Partial<ProductCtx> = {};

export const ProductInfoContex = createContext(productInfoContex);


export default function ModalProduct({ onClick, product, addCart, includeShop }: Props) {

  const { currentCurrency } = useAppSelector((state: RootState) => state.session)

  const [currentPrice, setCurrentPrice] = useState(product?.onSalePrice !== null ? product?.onSalePrice?.amount : product?.prices[0].price);
  const [currentCodeCurrency, setCurrentCodeCurrency] = useState(product?.onSalePrice !== null ? product?.onSalePrice?.codeCurrency : product.prices[0].codeCurrency);
  const [currentDescription, setCurrentDescription] = useState(product?.description);
  const [resultVariation, setResultVariation] = useState<VariationsProd | null>(null);

  const originalInfo: OriginalInfo = {
    price: product?.onSalePrice !== null ? product?.onSalePrice.amount : product?.prices[0].price,
    codeCurrency: product?.onSalePrice !== null ? product?.onSalePrice.codeCurrency : product.prices[0].codeCurrency,
    description: product?.description,
  }

  return (
    <ProductInfoContex.Provider
      value={
        {
          currentPrice,
          setCurrentPrice,
          currentDescription,
          setCurrentDescription,
          currentCodeCurrency,
          setCurrentCodeCurrency,
          originalInfo,
          setResultVariation,
          resultVariation
        }
      }>
      <Transition.Root show={true} as={Fragment} key={product?.id}>
        <Dialog
          as="div"
          className="relative z-40  rounded-lg "
          onClose={() => onClick()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-60"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-20"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 backdrop-blur-md backdrop-filter bg-gray-500 bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 top-32 left-3 right-3  rounded-lg  overflow-y-auto">
            <div className="flex h-auto items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                enterTo="opacity-100 translate-y-0 md:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 md:scale-100"
                leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              >
                <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                  <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pt-14 pb-8 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                    <button
                      type="button"
                      className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                      onClick={() => onClick()}
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </button>

                    <div className="grid w-full grid-cols-1 items-start gap-y-8 gap-x-6 sm:grid-cols-12 lg:gap-x-8">
                      <div className="sm:col-span-4 lg:col-span-5">
                        <div className="overflow-hidden rounded-lg bg-gray-100">

                          {/* Determines when to display an image carousel, a single image, and the default image */}
                          {
                            product?.images
                              .length !==
                              undefined &&
                              product?.images.length >
                              1
                              ? <CarrouselProductImages product={product} />
                              : (<img
                                src={
                                  product?.images
                                    .length !==
                                    undefined &&
                                    product?.images.length ===
                                    1
                                    ? product?.images[0]
                                      .src
                                    : imageDefault
                                }
                                alt={product?.name}
                                className="object-cover object-center"
                              />)
                          }
                        </div>
                      </div>
                      <div className="sm:col-span-8 lg:col-span-7">
                        <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">
                          {product?.name}
                        </h2>

                        <section
                          aria-labelledby="information-heading"
                          className="mt-3"
                        >
                          <div className="flex flex-row">
                            {product?.onSale && (
                              <p
                                className={`text-2xl mr-2 text-gray-900`}
                              >
                                {printPriceWithCommasAndPeriods(currentPrice) +
                                  " " +
                                  currentCodeCurrency}
                              </p>
                            )}
                            <p
                              className={` ${product?.onSale
                                ? "line-through text-slate-500 text-lg mt-1"
                                : "text-gray-900 text-2xl"
                                }`}
                            >
                              {
                                (resultVariation === null
                                  ? printPriceWithCommasAndPeriods(product?.prices.find(element => element.codeCurrency === currentCurrency)?.price)
                                  : printPriceWithCommasAndPeriods(currentPrice))
                                + (resultVariation === null
                                  ? (" " + currentCurrency)
                                  : (" " + currentCodeCurrency))

                              }
                            </p>

                          </div>

                          <div className="mt-6">
                            {product?.showRemainQuantities &&
                              <p className=
                                {classNames(
                                  `${getColorShowRemainQuantities(product?.totalQuantity)}`
                                  ,
                                  "text-xs font-medium truncate h-5 -mt-4"
                                )}
                              >
                                {" "}

                                {getShowRemainQuantities(product?.totalQuantity)

                                }
                              </p>}
                            <h4 className="sr-only">
                              Description
                            </h4>

                            <p className="text-sm text-gray-700">
                              {currentDescription}
                            </p>
                          </div>
                        </section>

                        <section
                          aria-labelledby="options-heading"
                          className="mt-6"
                        >
                          <form>

                            {
                              product?.type === "VARIATION"
                              && (
                                <ProductVariations variations={product.variations} />
                              )
                            }
                            <div className="mt-6">

                              {includeShop &&
                                <>
                                  <button
                                    type="button"
                                    className={
                                      product?.stockLimit && product?.totalQuantity === 0
                                        ? "w-full overflow-hidden truncate inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-red-600  py-2 text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-red-900 focus:ring-offset-2"
                                        : product?.stockLimit && product?.totalQuantity > 0
                                          ? `${(product.type === "VARIATION" && resultVariation === null) ? "bg-gray-100 text-gray-700 hover:bg-gray-200 focus:ring-gray-500" : "bg-yellow-400 text-white hover:bg-yellow-400 focus:ring-yellow-500"} w-full overflow-hidden truncate inline-flex  items-center justify-center text-center rounded-md border border-transparent py-2 text-sm font-medium  focus:outline-none focus:ring-2  focus:ring-offset-2`
                                          : product?.stockLimit
                                            ? "w-full overflow-hidden truncate inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-red-600  py-2 text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-red-900 focus:ring-offset-2"
                                            : `${(product?.type === "VARIATION" && resultVariation === null) ? "bg-gray-100 text-gray-700 hover:bg-gray-200 focus:ring-gray-500" : "bg-yellow-400 text-white hover:bg-yellow-400 focus:ring-yellow-500"} w-full overflow-hidden truncate inline-flex  items-center justify-center text-center rounded-md border border-transparent py-2 text-sm font-medium  focus:outline-none focus:ring-2  focus:ring-offset-2`
                                    }
                                    onClick={() => {
                                      if (product?.stockLimit === false || (product?.stockLimit && product.totalQuantity > 0)) {
                                        if (product.type === "VARIATION") {
                                          if (resultVariation !== null) {
                                            onClick()
                                            addCart(
                                              {
                                                ...product,
                                                id: product.id,
                                                variationId: resultVariation.id,
                                                name: product.name + " " + resultVariation.name,
                                                prices: [
                                                  {
                                                    price: resultVariation.price ? resultVariation.price.amount : originalInfo.price,
                                                    codeCurrency: resultVariation.price ? resultVariation.price.codeCurrency : originalInfo.codeCurrency
                                                  }
                                                ],
                                                totalQuantity: resultVariation.stockAreaVariations[0].quantity
                                              }
                                            )

                                          }
                                        } else {
                                          onClick()
                                          addCart({
                                            ...product,
                                            prices: [
                                              {
                                                price: product.prices.find(elem => elem.codeCurrency === currentCurrency)?.price,
                                                codeCurrency: product.prices.find(elem => elem.codeCurrency === currentCurrency)?.codeCurrency
                                              }
                                            ]
                                          })
                                        }
                                      }
                                    }}
                                  >



                                    {product?.stockLimit && product?.totalQuantity === 0
                                      ? "Agotado"
                                      : product?.stockLimit && product?.totalQuantity > 0
                                        ? "Añadir a mi lista de deseos"
                                        : product?.stockLimit
                                          ? "Agotado"
                                          : "Añadir a mi lista de deseos"
                                    }
                                  </button>

                                </>}
                            </div>
                          </form>
                        </section>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </ProductInfoContex.Provider>
  );
}